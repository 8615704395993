var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validateUser"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor HP","label-for":"login-phone"}},[_c('validation-provider',{attrs:{"name":"Nomor HP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null,attrs:{"prepend":"+62"}},[_c('b-form-input',{attrs:{"id":"login-phone","type":"number","name":"login-phone","placeholder":"85288898889","state":errors.length > 0 ? false:null},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.roles.length)?_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Role","options":_vm.roles,"label":"name"},model:{value:(_vm.userData.role),callback:function ($$v) {_vm.$set(_vm.userData, "role", $$v)},expression:"userData.role"}}):_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mb-0",attrs:{"width":"100%","height":"40px"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Status Aktif","label-for":"status"}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.userData.status),callback:function ($$v) {_vm.$set(_vm.userData, "status", $$v)},expression:"userData.status"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Reference Accurate 1","label-for":"reference1"}},[_c('validation-provider',{attrs:{"name":"Reference Accurate 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.salesAccurate1.length)?_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Reference Accurate 1","options":_vm.salesAccurate1,"label":"name","clearable":false},model:{value:(_vm.userData.reference_accurate_id_1),callback:function ($$v) {_vm.$set(_vm.userData, "reference_accurate_id_1", $$v)},expression:"userData.reference_accurate_id_1"}}):_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mb-0",attrs:{"width":"100%","height":"40px"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Reference Accurate 2","label-for":"reference2"}},[_c('validation-provider',{attrs:{"name":"Reference Accurate 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.salesAccurate2.length)?_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Reference Accurate 2","options":_vm.salesAccurate2,"label":"name","clearable":false},model:{value:(_vm.userData.reference_accurate_id_2),callback:function ($$v) {_vm.$set(_vm.userData, "reference_accurate_id_2", $$v)},expression:"userData.reference_accurate_id_2"}}):_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mb-0",attrs:{"width":"100%","height":"40px"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }