<template>
  <div>
    <div
      v-if="loading"
      key="1"
      class="d-flex align-items-center justify-content-center"
      style="height: 80vh"
    >
      <b-spinner variant="primary" />
    </div>
    <div
      v-else
      key="2"
    >
      <back-navigation :title="+id === $store.getters.getCurrentUser.user.id ? 'Profil Saya' : 'Detail User'">
        <template
          #action
        >
          <div
            v-if="$root.role === 2"
            class="ml-auto d-flex flex-column align-items-end justify-content-end"
          >
            <b-collapse
              :visible="!editMode"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.resetPassword
                variant="outline-warning"
                class="p-50 font-weight-normal"
              >
                Reset Password
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="ml-1 p-50"
                @click="editMode = !editMode"
              >
                <feather-icon
                  icon="EditIcon"
                  size="14"
                />
              </b-button>
            </b-collapse>
            <b-collapse
              :visible="editMode"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                class="ml-auto p-50"
                @click="editMode = !editMode"
              >
                <feather-icon
                  icon="XIcon"
                  size="14"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="ml-1 py-50 px-75 font-weight-normal"
                @click="$refs.editUser.updateUser()"
              >
                Update
              </b-button>
            </b-collapse>
          </div>
          <b-button
            v-else-if="isAuthorized"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-auto mb-25 p-50"
            :to="{ name: 'profilesetting' }"
          >
            <feather-icon
              icon="EditIcon"
              size="14"
            />
          </b-button>
        </template>
      </back-navigation>
      <XyzTransitionGroup
        appear
        class="item-group"
        xyz="fade stagger-3 left-3 duration-6 delay-3"
      >
        <b-card key="1">
          <b-collapse :visible="!editMode">
            <b-row>
              <b-col
                cols="12"
                lg="4"
              >
                <div class="d-flex justify-content-start align-items-start">
                  <b-avatar
                    :text="avatarText(userData.name)"
                    :src="userData.photo ? userData.photo : null"
                    variant="light-primary"
                    size="64px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1 mb-50">
                    <h5 class="mb-0">
                      {{ userData.name }}
                    </h5>
                    <h6 class="text-muted m-0 mt-25 font-weight-normal">
                      {{ userData.phone }}
                    </h6>
                    <b-badge
                      class="mt-50"
                      variant="light-primary"
                    >
                      {{ userData.role.name }}
                    </b-badge>
                  </div>
                  <b-badge
                    class="ml-auto"
                    :variant="userData.status === 0 ? 'secondary' : 'primary'"
                  >
                    {{ userData.status === 0 ? 'Tidak Aktif' : 'Aktif' }}
                  </b-badge>
                </div>
                <small class="d-block text-muted mt-1">Tanda Tangan</small>
                <b-img
                  v-if="userData.ttd"
                  class="mt-50"
                  height="50"
                  :src="userData.ttd"
                />
                <b-badge
                  v-else
                  variant="secondary"
                  class="mt-50 p-2"
                >
                  <small>Belum Diset</small>
                </b-badge>
              </b-col>
              <b-col
                cols="12"
                lg="4"
                class="mt-1 mt-lg-0"
              >
                <small class="text-muted">
                  ID Accurate 1
                </small>
                <h6
                  v-if="userData.reference_accurate_id_1"
                  class="m-0 mb-50"
                >
                  {{ userData.reference_accurate_id_1 }}
                </h6>
                <h6
                  v-else
                  class="text-muted"
                >
                  Belum Diset
                </h6>
                <small class="text-muted">
                  ID Accurate 2
                </small>
                <h6
                  v-if="userData.reference_accurate_id_2"
                  class="m-0 mb-50"
                >
                  {{ userData.reference_accurate_id_2 }}
                </h6>
                <h6
                  v-else
                  class="text-muted"
                >
                  Belum Diset
                </h6>
                <small class="text-muted d-block">
                  DB Reference
                </small>
                <h6
                  v-if="userData.reference_db.name"
                  class="m-0"
                >
                  {{ userData.reference_db.name }}
                </h6>
                <h6
                  v-else
                  class="text-muted"
                >
                  Belum Diset
                </h6>
              </b-col>
              <b-col
                v-if="userData.role.id !== 4"
                cols="12"
                lg="4"
                class="mt-1 mt-lg-0"
              >
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <h5 class="m-0">
                    Target Omzet
                  </h5>
                  <b-button
                    v-if="$root.role === 2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="p-50"
                    @click="$refs.targetModal.show()"
                  >
                    <small class="font-weight-bold">Set Target</small>
                  </b-button>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">
                    Omzet Rupiah 1
                  </small>
                  <small>
                    {{ shortNumber(omzetUser.omzet_rupiah) }} / {{ shortNumber(userData.target_rupiah) }}
                  </small>
                </div>
                <b-progress
                  class="mt-25 mb-50"
                  :value="omzetUser.omzet_rupiah"
                  :max="userData.target_rupiah"
                />
                <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">
                    Omzet Liter 1
                  </small>
                  <small>
                    {{ omzetUser.omzet_liter }} L / {{ userData.target_liter }} L
                  </small>
                </div>
                <b-progress
                  class="mt-25 mb-50"
                  :value="omzetUser.omzet_liter"
                  :max="userData.target_liter"
                />
                <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">
                    Omzet Rupiah 2
                  </small>
                  <small>
                    {{ shortNumber(omzetUser.omzet_rupiah_2) }} / {{ shortNumber(userData.target_rupiah_2) }}
                  </small>
                </div>
                <b-progress
                  class="mt-25 mb-50"
                  :value="omzetUser.omzet_rupiah_2"
                  :max="userData.target_rupiah_2"
                />
                <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">
                    Omzet Liter 2
                  </small>
                  <small>
                    {{ omzetUser.omzet_liter_2 }} L / {{ userData.target_liter_2 }} L
                  </small>
                </div>
                <b-progress
                  class="mt-25 mb-50"
                  :value="omzetUser.omzet_liter_2"
                  :max="userData.target_liter_2"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <b-collapse :visible="editMode">
            <edit-user
              ref="editUser"
              :initial-data="userData"
              @closeForm="editMode = false; getDetailUser()"
            />
          </b-collapse>
        </b-card>
        <b-card key="2">
          <user-chart :user-id="+id" />
        </b-card>
        <b-row
          key="3"
          class="match-height"
        >
          <b-col
            cols="12"
            lg="6"
            xl="5"
          >
            <b-card
              v-if="userData"
              no-body
            >
              <div
                class="mt-1 overflow-auto px-2"
                style="max-height: 70vh"
              >
                <vue-perfect-scrollbar
                  :settings="{
                    maxScrollbarLength: 300,
                  }"
                  class="list-group scroll-area overflow-hidden pb-2"
                >
                  <daily-report
                    :id="id"
                    type="user"
                  />
                </vue-perfect-scrollbar>
              </div>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="6"
            xl="7"
          >
            <b-row class="match-height h-100">
              <b-col
                cols="12"
              >
                <assigned-customer :id="+id" />
              </b-col>
              <b-col
                cols="12"
              >
                <omzet-card :user-id="+id" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <tasks-performance-user
          :id="id"
          key="4"
        />
      </XyzTransitionGroup>
    </div>

    <set-target-user
      v-if="$root.role === 2"
      :id="+id"
      ref="targetModal"
    />

    <b-modal
      id="resetPassword"
      ref="resetPassword"
      hide-footer
      centered
      title="Reset Password"
      @hidden="newPassword = ''; passwordScore = '';"
    >
      <b-overlay :show="loadingNewPassword">
        <div class="d-block">
          <validation-observer ref="validatePassword">
            <b-form @submit.prevent="submitNewPassword">
              <validation-provider
                #default="{ errors }"
                name="Password Baru"
                rules="required"
              >
                <b-form-group
                  label="Password Baru"
                  label-for="password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="newPassword"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      placeholder="Password Baru"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger ml-auto">{{ errors[0] }}</small>
              </validation-provider>
              <b-row class="mt-50">
                <b-col>
                  <password
                    v-model="newPassword"
                    :strength-meter-only="true"
                    @score="showScore"
                  />
                </b-col>
                <b-col
                  v-if="passwordScore.variant"
                  class="d-flex"
                  cols="auto"
                >
                  <small :class="`ml-auto ${passwordScore.variant}`">{{ passwordScore.message }}</small>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
        <div class="d-flex justify-content-center mt-2 mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mr-1"
            @click="$bvModal.hide('resetPassword')"
          >
            Batalkan
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="submitNewPassword"
          >
            Reset Password
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BSpinner, BAvatar, BBadge, BImg, BProgress, BCollapse,
  VBModal, BModal, BFormGroup, BFormInput, BForm, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import { avatarText, shortNumber } from '@core/utils/filter'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Password from 'vue-password-strength-meter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DailyReport from '@/components/misc/DailyReport.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import OmzetCard from '@/views/home/OmzetCard.vue'
import EditUser from './EditUser.vue'
import AssignedCustomer from './AssignedCustomer.vue'
import UserChart from './UserChart.vue'
import SetTargetUser from './SetTargetUser.vue'
import TasksPerformanceUser from './TasksPerformanceUser.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BAvatar,
    BBadge,
    BImg,
    BProgress,
    BCollapse,
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    Password,
    EditUser,
    BackNavigation,
    AssignedCustomer,
    UserChart,
    DailyReport,
    SetTargetUser,
    VuePerfectScrollbar,
    OmzetCard,
    TasksPerformanceUser,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      userData: {},
      avatarText,
      shortNumber,
      editMode: false,
      newPassword: '',
      passwordScore: '',
      passwordFieldTypeNew: 'password',
      loadingNewPassword: false,
      omzetUser: {
        omzet_rupiah: 0,
        omzet_liter: 0,
        omzet_rupiah_2: 0,
        omzet_liter_2: 0,
      },
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isAuthorized() {
      return +this.id === this.$store.getters.getCurrentUser.user.id
    },
  },
  created() {
    this.getDetailUser()
    this.getOmzetUser()
  },
  methods: {
    getDetailUser() {
      this.loading = true
      this.$store.dispatch('getDetailUser', +this.id).then(result => {
        if (this.edit) {
          this.editMode = true
        }
        // eslint-disable-next-line prefer-destructuring
        this.userData = result.data.users.users[0]
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.$router.replace({ name: 'notfound' })
      })
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    submitNewPassword() {
      this.$refs.validatePassword.validate().then(success => {
        if (success) {
          this.loadingNewPassword = true
          this.$store.dispatch('resetPassword', {
            user_id: +this.id,
            newPassword: this.newPassword,
          }).then(result => {
            if (result.data.resetPassword.status === 'success') {
              this.loadingNewPassword = false
              this.$refs.resetPassword.hide()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mereset password!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            }
          }).catch(() => {
            this.loadingNewPassword = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal mereset password!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    showScore(score) {
      if (score >= 0 && score < 2) {
        this.passwordScore = {
          variant: 'text-danger',
          message: 'Password Lemah',
        }
      } else if (score === 3) {
        this.passwordScore = {
          variant: 'text-info',
          message: 'Password Cukup Bagus',
        }
      } else if (score > 3) {
        this.passwordScore = {
          variant: 'text-success',
          message: 'Password Sangat Bagus',
        }
      }

      if (score === null) {
        this.passwordScore = {
          variant: '',
          message: '',
        }
      }
    },
    getOmzetUser() {
      this.$store.dispatch('getPerformanceTargetSales', {
        data_source: 'Q',
        ref_db: this.$store.state.reference.selectedDB.id === 0 ? 1 : 0,
        user_id: +this.id,
      }).then(data => {
        const state = this.$store.state.dashboard.performanceOmzet.omzetData
        if (this.$store.state.reference.selectedDB.id === 0) {
          this.omzetUser = {
            omzet_rupiah: state.accumulation_total ? state.accumulation_total : 0,
            omzet_liter: state.accumulation_total_liter ? state.accumulation_total_liter : 0,
            omzet_rupiah_2: data.accumulation_total ? data.accumulation_total : 0,
            omzet_liter_2: data.accumulation_total_liter ? data.accumulation_total_liter : 0,
          }
        } else {
          this.omzetUser = {
            omzet_rupiah: data.accumulation_total ? data.accumulation_total : 0,
            omzet_liter: data.accumulation_total_liter ? data.accumulation_total_liter : 0,
            omzet_rupiah_2: state.accumulation_total ? state.accumulation_total : 0,
            omzet_liter_2: state.accumulation_total_liter ? state.accumulation_total_liter : 0,
          }
        }
      })
    },
  },
}
</script>

<style>
.Password {
  max-width: 100% !important;
}
.Password__strength-meter {
  margin-bottom: 8px;
}
</style>
