<template>
  <b-modal
    id="targetModal"
    scrollable
    title="Set Target"
    size="lg"
    body-class="p-0"
    :hide-footer="loading"
    @shown="fetchDetailTarget()"
    @hidden="resetForm()"
  >
    <div
      v-if="loading"
      style="height: 90vh;"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner
        variant="primary"
      />
    </div>
    <validation-observer
      v-else
      ref="validateTarget"
    >
      <XyzTransitionGroup
        appear
        class="mb-50"
        xyz="fade stagger-2 left-3 delay-2"
        mode="out-in"
      >

        <div
          v-for="(target, index) in targetData"
          :key="index"
        >
          <AppCollapse :key="index">
            <!-- <div class="p-2">
            <h6>{{ target.title }}</h6> -->
            <AppCollapseItem :title="target.title">

              <b-row>
                <b-col
                  lg="6"
                  cols="12"
                >
                  <b-form-group
                    label="Omzet Rupiah 1"
                    label-for="omzet"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Omzet Rupiah 1 ${target.title}`"
                      rules="required|min_value:0|max_value:999999999999|integer"
                    >
                      <b-input-group
                        prepend="Rp."
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="target.target_rupiah"
                          type="number"
                          placeholder="Omzet Rupiah 1"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  cols="12"
                >
                  <b-form-group
                    label="Omzet Liter 1"
                    label-for="omzet"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Omzet Liter 1 ${target.title}`"
                      rules="required|min_value:0|max_value:99999999|integer"
                    >
                      <b-input-group
                        append="Liter"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="target.target_liter"
                          type="number"
                          placeholder="Omzet Liter 1"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  cols="12"
                >
                  <b-form-group
                    label="Omzet Rupiah 2"
                    label-for="omzet"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Omzet Rupiah 2 ${target.title}`"
                      rules="required|min_value:0|max_value:999999999999|integer"
                    >
                      <b-input-group
                        prepend="Rp."
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="target.target_rupiah_2"
                          type="number"
                          placeholder="Omzet Rupiah 2"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                  cols="12"
                >
                  <b-form-group
                    label="Omzet Liter 2"
                    label-for="omzet"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Omzet Liter 2 ${target.title}`"
                      rules="required|min_value:0|max_value:99999999|integer"
                    >
                      <b-input-group
                        append="Liter"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="target.target_liter_2"
                          type="number"
                          placeholder="Omzet Liter 2"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </AppCollapseItem>
          <!-- </div>
          <hr class="m-0"> -->
          </AppCollapse>
        </div>
      </XyzTransitionGroup>
    </validation-observer>
    <template
      #modal-footer
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="$bvModal.hide('targetModal')"
      >
        Batalkan
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="loadingSubmit ? null : submitTarget()"
      >
        <span v-if="!loadingSubmit">Submit</span>
        <b-spinner
          v-else
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BCol, BRow, BModal, BButton, BFormGroup, BInputGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import moment from 'moment'
import { minValue, maxValue } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BCol,
    BRow,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      minValue,
      maxValue,
      loading: true,
      targetData: [],
      loadingSubmit: false,
    }
  },
  methods: {
    show() {
      this.$bvModal.show('targetModal')
    },
    getMonth(index) {
      // console.log(moment().add(index, 'months'))
      return {
        date: new Date(Date.parse(moment().add(index, 'months'))),
        title: moment().add(index, 'months').locale('id').format('MMMM YYYY'),
      }
    },
    fetchDetailTarget() {
      this.loading = true
      this.$store.dispatch('getTargetSales', this.id).then(result => {
        let iterator = -3
        const targetList = Array.from({ length: 27 }, (_, index) => {
          const newDateTarget = {
            target_date: new Date(this.getMonth(iterator).date).toISOString().slice(0, 10),
            title: this.getMonth(iterator).title,
            target_rupiah: 0,
            target_liter: 0,
            target_rupiah_2: 0,
            target_liter_2: 0,
          }
          console.log(index)
          iterator += 1
          return newDateTarget
        })

        result.forEach(data => {
          const index = targetList.findIndex(el => {
            console.log(moment(Date.parse(el.target_date)).format('MM/YYYY'), moment(Date.parse(data.target_date)).format('MM/YYYY'))
            return moment(Date.parse(el.target_date)).format('MM/YYYY') === moment(Date.parse(data.target_date)).format('MM/YYYY')
          })
          if (targetList[index]) {
            targetList[index] = {
              ...data,
              title: moment(Date.parse(targetList[index].target_date)).format('MMMM YYYY'),
            }
          }
        })

        this.targetData = targetList
        this.loading = false
      })
        .catch(err => {
          console.log(err)
          for (let i = -3; i < 27; i += 1) {
            this.targetData.push({
              target_date: new Date(this.getMonth(i).date).toISOString().slice(0, 10),
              title: this.getMonth(i).title,
              target_rupiah: 0,
              target_liter: 0,
              target_rupiah_2: 0,
              target_liter_2: 0,
            })
          }
          this.loading = false
        })
    },
    resetForm() {
      this.loading = true
      this.targetData = []
    },
    submitTarget() {
      this.$refs.validateTarget.validate().then(success => {
        if (success) {
          this.loadingSubmit = true
          this.$store.dispatch('setTargetSales', {
            user_id: this.id,
            target: this.targetData.map(data => ({
              target_date: data.target_date,
              target_rupiah: parseFloat(data.target_rupiah),
              target_liter: parseFloat(data.target_liter),
              target_rupiah_2: parseFloat(data.target_rupiah_2),
              target_liter_2: parseFloat(data.target_liter_2),
            })),
          }).then(() => {
            this.$bvModal.hide('targetModal')
            this.loadingSubmit = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menyimpan target!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            this.$bvModal.hide('targetModal')
            this.loadingSubmit = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menyimpan!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
