<template>
  <b-card
    ref="parent"
    class="h-100"
    no-body
    style="min-height: 240px"
  >
    <b-overlay :show="performanceOmzet.loadingChart">
      <div class="filter-omzet px-1 p-1 d-flex justify-content-end">
        <b-dropdown
          :text="omzetLabel"
          size="sm"
          variant="outline-primary"
          left
          class="mr-1 omzet-dropdown"
        >
          <b-dropdown-item
            v-for="mode in omzetSelection"
            :key="mode.value"
            :active="performanceOmzet.selectedOmzet === mode.value"
            @click="performanceOmzet.selectedOmzet = mode.value; fetchData()"
          >
            {{ mode.label }}
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-if="$root.role === 2 && !userId"
          :text="performanceOmzet.selectedPerson === 'mine' ? 'Saya' : 'Semua'"
          size="sm"
          variant="outline-primary"
          right
          class="omzet-dropdown"
        >
          <b-dropdown-item
            :active="performanceOmzet.selectedPerson === 'all'"
            @click="performanceOmzet.selectedPerson = 'all'; fetchData()"
          >
            Semua
          </b-dropdown-item>
          <b-dropdown-item
            :active="performanceOmzet.selectedPerson === 'mine'"
            @click="performanceOmzet.selectedPerson = 'mine'; fetchData()"
          >
            Saya
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <waterfall-slider
        :height="height > 280 ? userId ? 320 : 280 : height"
        :delay="6000"
        :loop="false"
      >
        <swiper-slide>
          <div class="px-2 pt-2 pb-0">
            <b-avatar
              class="mb-50"
              :variant="`light-primary`"
              size="45"
            >
              <feather-icon
                size="21"
                icon="CreditCardIcon"
              />
            </b-avatar>
            <div class="truncate">
              <small class="text-muted">Omzet Rupiah</small>
              <div class="d-flex align-items-center">
                <h2 class="mb-25 mt-25 font-weight-bolder">
                  Rp. {{ performanceOmzet.omzetData && performanceOmzet.omzetData.accumulation_total ? shortNumber(performanceOmzet.omzetData.accumulation_total) : 0 }}
                </h2>
                <Lottie
                  v-if="performanceOmzet.omzetData && performanceOmzet.omzetData.percentage_total_to_target && performanceOmzet.omzetData.percentage_total_to_target !== 0"
                  class="m-0 ml-25"
                  :options="{
                    animationData: performanceOmzet.omzetData.percentage_total_to_target > 0 ? arrowUp : arrowDown
                  }"
                  :height="24"
                  :width="24"
                />
                <small
                  v-if="performanceOmzet.omzetData && performanceOmzet.omzetData.percentage_total_to_target && performanceOmzet.omzetData.percentage_total_to_target !== 0"
                >
                  {{ roundDecimal(performanceOmzet.omzetData.percentage_total_to_target) }}% {{ performanceOmzet.omzetData.percentage_total_to_target > 0 ? 'di atas' : 'di bawah' }} target
                </small>
              </div>
            </div>
          </div>
          <vue-apex-charts
            v-if="performanceOmzet.omzetRupiah"
            type="area"
            height="100"
            width="100%"
            :options="chartOptionsRupiah"
            :series="performanceOmzet.omzetRupiah"
          />
        </swiper-slide>
        <swiper-slide>
          <div class="px-2 pt-2 pb-0">
            <b-avatar
              class="mb-50"
              :variant="`light-warning`"
              size="45"
            >
              <feather-icon
                size="21"
                icon="PackageIcon"
              />
            </b-avatar>
            <div class="truncate">
              <small class="text-muted">Omzet Liter</small>
              <div class="d-flex align-items-center">
                <h2 class="mb-25 mt-25 font-weight-bolder">
                  {{ performanceOmzet.omzetData && performanceOmzet.omzetData.accumulation_total_liter ? performanceOmzet.omzetData.accumulation_total_liter : 0 }} L
                </h2>
                <Lottie
                  v-if="performanceOmzet.omzetData && performanceOmzet.omzetData.percentage_total_liter_to_target && performanceOmzet.omzetData.percentage_total_liter_to_target !== 0"
                  class="m-0 ml-25"
                  :options="{
                    animationData: performanceOmzet.omzetData.percentage_total_liter_to_target > 0 ? arrowUp : arrowDown
                  }"
                  :height="24"
                  :width="24"
                />
                <small
                  v-if="performanceOmzet.omzetData && performanceOmzet.omzetData.percentage_total_liter_to_target && performanceOmzet.omzetData.percentage_total_liter_to_target !== 0"
                >
                  {{ roundDecimal(performanceOmzet.omzetData.percentage_total_liter_to_target) }}% {{ performanceOmzet.omzetData.percentage_total_liter_to_target > 0 ? 'di atas' : 'di bawah' }} target
                </small>
              </div>
            </div>
          </div>

          <vue-apex-charts
            v-if="performanceOmzet.omzetLiter"
            type="area"
            height="100"
            width="100%"
            :options="chartOptionsLiter"
            :series="performanceOmzet.omzetLiter"
          />
        </swiper-slide>
      </waterfall-slider>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, VBPopover, BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Lottie from 'vue-lottie'
import arrowUp from '@/assets/images/lottie/arrow-up.json'
import arrowDown from '@/assets/images/lottie/arrow-down.json'
import WaterfallSlider from '@/components/misc/WaterfallSlider.vue'
import { SwiperSlide } from 'vue-awesome-swiper'
import { useElementSize } from '@vueuse/core'
import { ref } from '@vue/composition-api'
import { createHelpers } from 'vuex-map-fields'
import { shortNumber, roundDecimal } from '@core/utils/filter'
import moment from 'moment'

const { mapFields } = createHelpers({
  getterType: 'getDashboardData',
  mutationType: 'updateDashboardData',
})

export default {
  components: {
    BCard,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BOverlay,
    VueApexCharts,
    WaterfallSlider,
    SwiperSlide,
    Lottie,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      arrowUp,
      arrowDown,
      shortNumber,
      roundDecimal,
      omzetSelection: [
        {
          label: 'Quote',
          value: 'Q',
        },
        {
          label: 'Sales Order',
          value: 'SO',
        },
        {
          label: 'Invoice',
          value: 'SI',
        },
      ],
      areaChartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'category',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
          categories: [],
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
            labels: { show: false },
          },
        ],
        tooltip: {
          x: { show: true },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: $themeColors.primary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
    }
  },
  computed: {
    chartOptionsRupiah() {
      const options = JSON.parse(JSON.stringify(this.areaChartOptions))
      options.theme.monochrome.color = $themeColors.primary
      return options
    },
    chartOptionsLiter() {
      const options = JSON.parse(JSON.stringify(this.areaChartOptions))
      options.theme.monochrome.color = $themeColors.warning
      return options
    },
    ...mapFields([
      'performanceOmzet',
    ]),
    omzetLabel() {
      return this.omzetSelection.filter(el => el.value === this.performanceOmzet.selectedOmzet)[0].label
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.userId) {
        this.performanceOmzet.selectedOmzet = 'Q'
      }
      this.fetchData()
    }, 1000)
  },
  methods: {
    fetchData() {
      this.performanceOmzet.loadingChart = true
      /* eslint-disable no-nested-ternary */
      this.$store.dispatch('getPerformanceTargetSales', {
        data_source: this.performanceOmzet.selectedOmzet,
        ref_db: this.$store.state.reference.selectedDB.id,
        user_id: this.userId ? this.userId : this.$root.role === 2
          ? this.performanceOmzet.selectedPerson === 'all'
            ? null
            : this.$store.state.user.currentUser.user.id
          : this.$store.state.user.currentUser.user.id,
      }).then(data => {
        this.performanceOmzet.omzetData = data

        const today = new Date()
        const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
        const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999)
        const diffTime = Math.abs(thisMonthEnd - thisMonthStart)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        const label = []
        const total = []
        const liter = []

        for (let i = 1; i <= diffDays; i += 1) {
          const date = moment(new Date(today.getFullYear(), today.getMonth(), i)).format('YYYY-MM-DD')
          const index = data.detail_performance.findIndex(el => el.date === date)
          total.push(index !== -1 ? data.detail_performance[index].total : 0)
          liter.push(index !== -1 ? data.detail_performance[index].total_liter : 0)
          label.push(date)
        }

        this.areaChartOptions.xaxis.categories = label
        this.performanceOmzet.omzetRupiah = [
          {
            name: 'Omzet Rupiah',
            data: total,
          },
        ]
        this.performanceOmzet.omzetLiter = [
          {
            name: 'Omzet Liter',
            data: liter,
          },
        ]

        this.performanceOmzet.loadingChart = false
      }).catch(() => {
        this.performanceOmzet.loadingChart = false
      })
    },
  },
  setup() {
    const parent = ref(null)
    const { width, height } = useElementSize(parent)

    return {
      width,
      height,
      parent,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/components/variables-dark';

.filter-omzet {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.omzet-dropdown {
  background: #fff;

  .dark-layout & {
    background: $theme-dark-body-bg !important;;
  }
}
</style>
