<template>
  <swiper
    class="swiper-autoplay w-100"
    :options="swiperOption"
    :style="`max-height: ${height}px !important;`"
  >
    <slot />
  </swiper>
</template>

<script>
import { Swiper } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
  },
  props: {
    height: {
      type: [Number, String],
      required: true,
      default: 200,
    },
    delay: {
      type: [Number, String],
      default: 5000,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      swiperOption: {
        speed: 800,
        direction: 'vertical',
        slidesPerView: 1,
        mousewheel: true,
        spaceBetween: 30,
        autoplay: {
          delay: +this.delay,
          disableOnInteraction: false,
        },
        loop: this.loop,
        centeredSlides: true,
      },
    }
  },
}
</script>

<style>

</style>
