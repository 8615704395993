<template>
  <b-card no-body>
    <div class="d-flex align-items-center pt-2 px-2">
      <h5 class="m-0">
        Daftar Customer
      </h5>
      <feather-icon
        icon="RotateCwIcon"
        size="18"
        class="ml-auto mr-2 cursor-pointer"
        @click="fetchCustomer()"
      />
      <b-button
        v-if="$root.role === 2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
        :disabled="loading"
        @click="$refs.assignCustomer.open()"
      >
        Tambah
      </b-button>
    </div>
    <div
      v-if="loading !== 'error'"
    >
      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center"
        style="height: 400px"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        class="mt-1 overflow-auto px-2"
        style="max-height: 30vh"
      >
        <vue-perfect-scrollbar
          v-if="customerList.length"
          :settings="{
            maxScrollbarLength: 300,
          }"
          class="list-group scroll-area overflow-hidden pb-2"
        >
          <div
            v-for="data in customerList"
            :key="data.id"
            class="d-flex justify-content-start align-items-center mt-1"
          >
            <div class="mr-1">
              <b-avatar
                size="40"
                :src="data.avatar"
                variant="light-primary"
                :text="avatarText(data.name)"
              />
            </div>
            <div class="profile-user-info">
              <router-link :to="{ name: 'customer-detail', params: { id: data.id.toString() } }">
                <h6 class="mb-0 text-primary">
                  {{ data.name }}
                </h6>
              </router-link>
              <small class="text-muted">{{ data.customerNo }}</small>
            </div>
          </div>
        </vue-perfect-scrollbar>
        <h6
          v-else
          class="text-muted text-center mb-2"
        >
          Belum ada customer
        </h6>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
    <vue-bottom-sheet
      ref="assignCustomer"
      :click-to-close="false"
      max-width="1200px"
      style="margin: 0 !important"
      @closed="selectedCustomer = customerList"
    >
      <div
        class="mx-2 p-1"
        style="height:80vh"
      >
        <div class="d-flex justify-content-between mb-1 align-content-center">
          <h4>Assign Customer</h4>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="$refs.assignCustomer.close()"
          />
        </div>
        <div>
          <customer-auto-suggest
            @onSelected="handleSelectedCustomer"
          />
          <hr>
          <div
            v-for="(data,index) in selectedCustomer"
            :key="index"
            class="d-flex justify-content-start align-items-center mt-1"
          >
            <div class="mr-1">
              <b-avatar
                size="40"
                :src="data.avatar"
                variant="light-primary"
                :text="avatarText(data.name)"
              />
            </div>
            <div class="profile-user-info">
              <h6 class="mb-0">
                {{ data.name }}
              </h6>
              <small class="text-muted">{{ data.customerNo }}</small>
            </div>
            <feather-icon
              icon="XIcon"
              size="20"
              class="text-danger ml-auto"
              @click="deselectCustomer(data)"
            />
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2 mb-2"
            variant="primary"
            block
            @click="loadingAssign ? null : assignCustomer()"
          >
            <span v-if="!loadingAssign">Simpan Daftar Customer</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </div>
    </vue-bottom-sheet>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BSpinner, BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import CustomerAutoSuggest from '@/components/input/CustomerAutoSuggest.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'

export default {
  components: {
    BCard,
    BAvatar,
    BSpinner,
    BButton,
    VueBottomSheet,
    CustomerAutoSuggest,
    VuePerfectScrollbar,
    ErrorEndpoint,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      avatarText,
      loading: true,
      customerList: [],
      selectedCustomer: [],
      loadingAssign: false,
      error: '',
    }
  },
  mounted() {
    this.fetchCustomer()
  },
  methods: {
    fetchCustomer() {
      this.loading = true
      this.$store.dispatch('getCustomerAssigned', { id: +this.id }).then(result => {
        this.customerList = result
        this.selectedCustomer = result
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.error = `${err.graphQLErrors[0].message}`
      })
    },
    handleSelectedCustomer(data) {
      if (!this.selectedCustomer.some(el => el.id === data.id)) {
        this.selectedCustomer = [...this.selectedCustomer, data]
      }
    },
    deselectCustomer(data) {
      // this.$refs.assignCustomer.close()
      // this.$swal({
      //   title: 'Hapus customer?',
      //   text: 'Konfirmasi jika anda ingin menghapus customer ini dari user ini.',
      //   showCancelButton: true,
      //   confirmButtonText: 'Yakin',
      //   cancelButtonText: 'Batalkan',
      //   customClass: {
      //     confirmButton: 'btn btn-danger',
      //     cancelButton: ' text-danger btn btn-outline-danger ml-1',
      //   },
      //   buttonsStyling: false,
      // }).then(result => {
      // if (result.value) {
      this.selectedCustomer = this.selectedCustomer.filter(el => el.id !== data.id)
      // }
      // this.$refs.assignCustomer.open()
      // })
    },
    assignCustomer() {
      this.loadingAssign = true
      this.$store.dispatch('assignCustomer', {
        user_id: +this.id,
        customer_id: this.selectedCustomer.length ? `[${this.selectedCustomer.map(el => `"${el.id}"`).join(', ')}]` : '[]',
      }).then(() => {
        this.$refs.assignCustomer.close()
        this.loadingAssign = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil mengassign customer!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.fetchCustomer()
      })
    },
  },
}
</script>
