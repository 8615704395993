<template>
  <div>
    <div class="d-flex flex-row flex-wrap justify-content-between align-items-center mb-1">
      <h6 class="m-0">
        Performance Penjualan User
      </h6>
      <div class="d-flex align-items-center">
        <small>Lihat Target User</small>
        <b-form-checkbox
          :checked="showTarget"
          switch
          class="ml-50"
          @change="showTarget = !showTarget; getTarget()"
        />
      </div>
    </div>
    <b-row class="mb-1">
      <b-col
        cols="12"
        xl="3"
        md="6"
        class="mb-1"
      >
        <v-select
          v-model="viewMode"
          placeholder="Pilih satuan"
          :options="typeMode.value === 'Q' ? [{ label: 'Rupiah', value: 'rp'}, { label: 'Liter', value: 'lt'},] : [{ label: 'Rupiah', value: 'rp'}]"
          :clearable="false"
          @option:selected="renderChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="6"
        class="mb-1"
      >
        <v-select
          v-model="refDb"
          placeholder="Ref DB"
          :options="[{ label: 'TSD', value: 0 }, { label: 'PD', value: 1 }]"
          :clearable="false"
          @option:selected="getChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="6"
        class="mb-1"
      >
        <v-select
          v-model="typeMode"
          placeholder="Pilih tipe"
          :options="[{ label: 'Quote', value: 'Q'}, { label: 'Sales Order', value: 'SO'}, { label: 'Sales Invoice', value: 'SI'},]"
          :clearable="false"
          @option:selected="getChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="6"
      >
        <date-range-picker
          v-model="date"
          :max-date="today"
          :min-date="new Date(today.getFullYear(), today.getMonth() - 12, 1)"
          :custom-range="range()"
          opens="left"
        />
      </b-col>
    </b-row>
    <b-row v-if="!notfound">
      <b-col
        cols="12"
        xl="8"
      >
        <div
          v-if="loadingChart"
          class="d-flex justify-content-center align-items-center"
          style="height: 20vh"
        >
          <b-spinner variant="primary" />
        </div>
        <chartjs-component-bar-chart
          v-else
          :key="chartData.toString()"
          :height="320"
          :data="chartData"
          :options="options"
        />
      </b-col>
      <b-col
        v-if="!loadingChart"
        cols="12"
        xl="4"
      >
        <small class="mb-50 text-primary">Akumulasi Penjualan</small>
        <div class="d-flex flex-wrap mb-1">
          <div class="mr-1">
            <small class="text-muted">Total</small>
            <h5>{{ formatCurrency(performanceData.accumulation_total) }}</h5>
          </div>
          <div class="mr-1">
            <small class="text-muted">Total Liter</small>
            <h5>{{ formatNumber(performanceData.accumulation_total_liter) }}</h5>
          </div>
          <div v-if="permission.viewHpp">
            <small class="text-muted">Total HPP</small>
            <h5>{{ formatCurrency(performanceData.accumulation_total_hpp) }}</h5>
          </div>
        </div>
        <swiper
          v-if="topItems.length"
          :options="swiperOption"
        >
          <swiper-slide>
            <h6
              class="text-center mt-2"
            >
              Top 5 Items
            </h6>
            <vue-apex-charts
              v-if="topItems.length"
              height="180"
              :options="pieChartOption"
              :series="topItems"
            />
          </swiper-slide>
          <swiper-slide>
            <h6
              class="text-center mt-2"
            >
              Top 5 Customers
            </h6>
            <vue-apex-charts
              v-if="topCustomers.length"
              height="180"
              :options="pieChartCustomers"
              :series="topCustomers"
            />
          </swiper-slide>
        </swiper>
        <div
          v-else
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <Lottie
            class="w-50 mb-1"
            :options="{
              animationData: noresult,
            }"
          />
          <h6 class="text-muted font-weight-normal mb-1">
            Tidak ada top items 😞
          </h6>
        </div>
      </b-col>
    </b-row>
    <div
      v-else
      style="height: 400px"
    >
      <h6 class="text-muted">
        Tidak ada data
      </h6>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, VBToggle, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import { formatCurrency, avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import Lottie from 'vue-lottie'
import noresult from '@/assets/images/lottie/noresult.json'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ChartjsComponentBarChart from '../../components/chart/ChartjsComponentBarChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BFormCheckbox,
    ChartjsComponentBarChart,
    vSelect,
    DateRangePicker,
    VueApexCharts,
    Lottie,
    Swiper,
    SwiperSlide,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    userId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      noresult,
      formatCurrency,
      avatarText,
      loadingChart: true,
      notfound: false,
      viewMode: { label: 'Rupiah', value: 'rp' },
      typeMode: { label: 'Quote', value: 'Q' },
      performanceData: '',
      showTarget: false,
      targetUser: [],
      refDb: { label: 'TSD', value: 0 },
      swiperOption: {
        speed: 800,
        slidesPerView: 1,
        mousewheel: true,
        spaceBetween: 30,
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        loop: false,
        centeredSlides: true,
      },
      chartData: {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [
          {
            label: 'Total HPP',
            data: [],
            borderColor: '#17a2b8',
            type: 'line',
            fill: true,
            spanGaps: true,
          },
          {
            label: 'Target',
            data: [],
            borderColor: $themeColors.warning,
            type: 'line',
            spanGaps: true,
          },
          {
            label: 'Penjualan User',
            data: [],
            backgroundColor: '#6DAB3C',
            borderColor: 'transparent',
            stack: 'Stack 1',
            barBottom: true,
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        interaction: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                min: 0,
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
        },
      },
      date: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1).setHours(0, 0, 0, 0),
        endDate: new Date().setHours(0, 0, 0, 0),
      },
      filterMode: false,
      today: new Date(),
      pieChart: {
        chart: {
          width: '100%',
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'right',
          labels: {
            colors: $themeColors.secondary,
          },
          formatter: name => (name.length > 12 ? `${name.substring(0, 12)}...` : name),
        },
        comparedResult: [2, -3, 8],
        labels: [],
        stroke: { width: 0 },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.info, $themeColors.danger, '#6256EE', $themeColors.secondary],
        grid: {
          padding: {
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      pieChartCustomers: {},
      topItems: [],
      topCustomers: [],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
    }),
    pieChartOption() {
      const viewMode = this.viewMode.value
      const that = this
      return {
        ...this.pieChart,
        tooltip: {
          y: {
            formatter(value, { dataPointIndex }) {
              /* eslint-disable camelcase */
              const { total_liter } = that.performanceData.top_items[dataPointIndex]
              const { total } = that.performanceData.top_items_liter[dataPointIndex]
              return viewMode === 'rp' ? `${formatCurrency(value)} (${total_liter} L)` : `${value} L (${formatCurrency(total)})`
              /* eslint-enable camelcase */
            },
          },
        },
      }
    },
  },
  watch: {
    date: {
      handler() {
        this.getChart()
      },
    },
  },
  created() {
    this.refDb = {
      label: this.$store.state.reference.selectedDB.name,
      value: this.$store.state.reference.selectedDB.id,
    }
    this.getChart()
  },
  methods: {
    async getChart() {
      this.loadingChart = true

      if (this.typeMode.value !== 'Q') {
        this.viewMode = { label: 'Rupiah', value: 'rp' }
      }

      this.$store.dispatch('getPerformancePenjualan', {
        date: {
          date_min: new Date(this.date.startDate).toISOString().slice(0, 10),
          date_max: new Date(this.date.endDate).toISOString().slice(0, 10),
        },
        user_id: this.userId,
        data_source: this.typeMode.value,
        ref_db: this.refDb.value,
      }).then(async mySale => {
        this.performanceData = {
          ...mySale,
          detail_performance: mySale.detail_performance,
        }

        this.renderChart()
      })
    },
    renderChart() {
      this.resetChart()
      this.loadingChart = true

      const labels = []

      if (this.performanceData.detail_performance && this.performanceData.detail_performance.length) {
        this.performanceData.detail_performance.forEach(data => {
          if (!labels.some(el => el === this.formatMonth(data.date))) {
            labels.push(this.formatMonth(data.date))
          }
        })
      }

      labels.forEach((el, index) => {
        this.chartData.labels[index] = el
      })

      if (this.performanceData.detail_performance && this.performanceData.detail_performance.length) {
        this.performanceData.detail_performance.forEach(data => {
          const index = this.chartData.labels.findIndex(el => el === this.formatMonth(data.date))
          // eslint-disable-next-line no-nested-ternary
          this.chartData.datasets[0].data[index] = this.permission.viewHpp ? this.viewMode.value === 'rp' ? data.total_hpp : 0 : null
          this.chartData.datasets[2].data[index] = this.viewMode.value === 'rp' ? data.total : this.formatNumber(data.total_liter)
        })
      } else {
        this.chartData.datasets[2].data = []
        this.chartData.datasets[0].data = []
      }

      if (!this.performanceData.detail_performance) {
        this.notfound = true
      }

      if (this.performanceData.top_items || this.performanceData.top_items_liter) {
        const dataSource = this.performanceData[this.viewMode.value === 'rp' ? 'top_items' : 'top_items_liter']

        this.pieChartOption.labels = dataSource.length ? dataSource
          .filter(data => (this.viewMode.value === 'rp' ? data.total > 0 : data.total_liter > 0))
          .map(data => data.item_code) : []
        this.topItems = dataSource.length ? dataSource
          .filter(data => (this.viewMode.value === 'rp' ? data.total > 0 : data.total_liter > 0))
          .map(data => (this.viewMode.value === 'rp' ? data.total ?? 0 : data.total_liter ?? 0)) : []
      }

      if (this.performanceData.top_customers || this.performanceData.top_customers_liter) {
        this.pieChartCustomers = { ...this.pieChartOption }
        const dataSource = this.performanceData[this.viewMode.value === 'rp' ? 'top_customers' : 'top_customers_liter']

        this.pieChartCustomers.labels = dataSource.length ? dataSource
          .filter(data => (this.viewMode.value === 'rp' ? data.total > 0 : data.total_liter > 0))
          .map(data => data.customer_id) : []
        this.topCustomers = dataSource.length ? dataSource
          .filter(data => (this.viewMode.value === 'rp' ? data.total > 0 : data.total_liter > 0))
          .map(data => (this.viewMode.value === 'rp' ? data.total ?? 0 : data.total_liter ?? 0)) : []
      }

      if (this.targetUser.length) {
        this.chartData.labels.forEach((label, index) => {
          if (label) {
            const target = this.targetUser.filter(el => el.month === label)[0]
            /* eslint-disable no-nested-ternary */
            if (target) {
              this.chartData.datasets[1].data[index] = this.refDb.value === 0
                ? this.viewMode.value === 'rp'
                  ? target.target_rupiah
                  : target.target_liter
                : this.viewMode.value === 'rp'
                  ? target.target_rupiah_2
                  : target.target_liter_2
            } else {
              this.chartData.datasets[1].data[index] = 0
            }
          }
        })
      }

      setTimeout(() => {
        this.loadingChart = false
      }, 500)
    },
    getTarget() {
      if (this.showTarget && !this.targetUser.length) {
        this.$store.dispatch('getTargetSales', this.userId).then(result => {
          this.targetUser = result.map(data => ({
            ...data,
            month: this.formatMonth(data.target_date),
          }))
          this.renderChart()
        })
      } else {
        this.targetUser = []
        this.renderChart()
      }
    },
    resetChart() {
      this.notfound = false
      this.chartData.datasets[0].data = []
      this.chartData.datasets[1].data = []
      this.chartData.datasets[2].data = []
      this.chartData.labels = ['', '', '', '', '', '', '', '', '', '', '', '', '']
      this.pieChartOption.labels = []
      this.topItems = []
      this.topCustomers = []
    },
    range() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayEnd = new Date()
      todayEnd.setHours(11, 59, 59, 999)
      const yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0)
      const yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(11, 59, 59, 999)
      /* eslint-disable quote-props */
      return {
        '3 Bulan Terakhir': [new Date(today.getFullYear(), today.getMonth() - 3, 1), today],
        '6 Bulan Terakhir': [new Date(today.getFullYear(), today.getMonth() - 6, 1), today],
        '1 Tahun Terakhir': [new Date(today.getFullYear(), today.getMonth() - 12, 1), today],
        'Tahun ini': [new Date(today.getFullYear(), 0, 1), today],
      }
    },
    chartColors() {
      return {
        primaryColorShade: '#6DAB3C',
        primaryLighteColorShade: 'rgba(109, 171, 60, 0.4)',
        secondaryColorShade: '#ff9f43',
        secondaryLightColorShade: 'rgba(255,159,67, 0.4)',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      }
    },
    applyFilter() {
      this.getChart()
      this.filterMode = false
    },
    formatNumber(data) {
      return Math.ceil(data * 100) / 100
    },
    formatMonth(date) {
      const parsedDate = new Date(Date.parse(date))

      return moment(parsedDate).locale('id').format('MM/YY')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
