<template>
  <validation-observer ref="validateUser">
    <b-form>
      <b-row>
        <b-col
          cols="12"
          lg="4"
          md="6"
        >
          <b-form-group
            label="Nomor HP"
            label-for="login-phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Nomor HP"
              rules="required"
            >
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid':null"
                class="input-group-merge"
                prepend="+62"
              >
                <b-form-input
                  id="login-phone"
                  v-model="userData.phone"
                  type="number"
                  name="login-phone"
                  placeholder="85288898889"
                  :state="errors.length > 0 ? false:null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          md="6"
        >
          <b-form-group
            label="Role"
            label-for="role"
          >
            <validation-provider
              #default="{ errors }"
              name="Role"
              rules="required"
            >
              <v-select
                v-if="roles.length"
                v-model="userData.role"
                placeholder="Role"
                :options="roles"
                label="name"
                class="w-100"
              />
              <div
                v-else
                class="d-flex"
              >
                <b-skeleton
                  width="100%"
                  height="40px"
                  class="mb-0"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          md="6"
        >
          <b-form-group
            label="Status Aktif"
            label-for="status"
          >
            <b-form-checkbox
              v-model="userData.status"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="4"
          md="6"
        >
          <b-form-group
            label="Reference Accurate 1"
            label-for="reference1"
          >
            <validation-provider
              #default="{ errors }"
              name="Reference Accurate 1"
            >
              <v-select
                v-if="salesAccurate1.length"
                v-model="userData.reference_accurate_id_1"
                placeholder="Reference Accurate 1"
                :options="salesAccurate1"
                class="w-100"
                label="name"
                :clearable="false"
              />
              <div
                v-else
                class="d-flex"
              >
                <b-skeleton
                  width="100%"
                  height="40px"
                  class="mb-0"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          md="6"
        >
          <b-form-group
            label="Reference Accurate 2"
            label-for="reference2"
          >
            <validation-provider
              #default="{ errors }"
              name="Reference Accurate 2"
            >
              <v-select
                v-if="salesAccurate2.length"
                v-model="userData.reference_accurate_id_2"
                placeholder="Reference Accurate 2"
                :options="salesAccurate2"
                class="w-100"
                label="name"
                :clearable="false"
              />
              <div
                v-else
                class="d-flex"
              >
                <b-skeleton
                  width="100%"
                  height="40px"
                  class="mb-0"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BFormCheckbox, VBPopover,
  BSkeleton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { createHelpers } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { mapFields } = createHelpers({
  getterType: 'getDBField',
  mutationType: 'updateDBField',
})

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BFormCheckbox,
    BSkeleton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    initialData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      userData: {
        phone: '',
        reference_accurate_id_1: '',
        reference_accurate_id_2: '',
        role: '',
        active: true,
      },
      roleOptions: [],
    }
  },
  computed: {
    ...mapFields([
      'salesAccurate1',
      'salesAccurate2',
      'roles',
    ]),
    edited() {
      if (`62${this.userData.phone}` !== this.initialData.phone) return true
      if (this.userData.role !== this.initialData.role) return true
      if (this.initialData.reference_accurate_id_1 !== this.userData.reference_accurate_id_1.id) return true
      if (this.initialData.reference_accurate_id_2 !== this.userData.reference_accurate_id_2.id) return true
      return false
    },
  },
  watch: {
    userData: {
      handler(data) {
        if (data.phone.toString().startsWith('62') && data.phone.length > 4) {
          this.userData.phone = +data.phone.substring(2)
        }

        if (data.phone.toString().startsWith('08') && data.phone.length > 4) {
          this.userData.phone = +data.phone.substring(1)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchSomeStuff()
    this.userData = {
      phone: this.initialData.phone,
      status: this.initialData.status === 1,
      reference_accurate_id_1: '',
      reference_accurate_id_2: '',
      role: this.initialData.role,
    }
  },
  methods: {
    async fetchSomeStuff() {
      if (!this.roles.length) {
        this.$store.dispatch('getRoles')
      }

      if (!this.salesAccurate2.length) {
        await this.$store.dispatch('getSalesAccurate', 1).then(data => {
          this.salesAccurate2 = data.map(el => ({
            id: el.id,
            name: el.name,
          }))
        })
      }

      if (!this.salesAccurate1.length) {
        await this.$store.dispatch('getSalesAccurate', 0).then(data => {
          this.salesAccurate1 = data.map(el => ({
            id: el.id,
            name: el.name,
          }))
        })
      }

      if (this.initialData.reference_accurate_id_1) {
        // eslint-disable-next-line prefer-destructuring
        this.userData.reference_accurate_id_1 = this.salesAccurate1.filter(el => el.id === this.initialData.reference_accurate_id_1)[0]
      }

      if (this.initialData.reference_accurate_id_2) {
        // eslint-disable-next-line prefer-destructuring
        this.userData.reference_accurate_id_2 = this.salesAccurate2.filter(el => el.id === this.initialData.reference_accurate_id_2)[0]
      }
    },
    async updateUser() {
      if (this.userData.status !== (this.initialData.status === 1)) {
        this.$store.dispatch('activeDeactiveUser', this.initialData.id).then(result => {
          if (result.data.activateDeactivateUser.status === 'success') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Berhasil ${this.userData.status ? 'mengaktifkan' : 'menonaktifkan'} user!`,
                icon: 'EditIcon',
                variant: 'success',
              },
            })

            if (!this.edited) {
              this.$emit('closeForm')
            }
          }
        })
      }

      this.$refs.validateUser.validate().then(success => {
        if (success && this.edited) {
          this.$store.dispatch('updateUserByManager', {
            user_id: this.initialData.id,
            reference_accurate_id_1: this.userData.reference_accurate_id_1 ? this.userData.reference_accurate_id_1.id : null,
            reference_accurate_id_2: this.userData.reference_accurate_id_2 ? this.userData.reference_accurate_id_2.id : null,
            phone: `"62${this.userData.phone}"`,
            role_id: this.userData.role.id,
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil mengubah data!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$emit('closeForm')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal mengubah data!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
