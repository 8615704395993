<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 20vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-if="!loading"
        key="2"
      >
        <div v-if="activities.length > 0">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <h5>
              Aktivitas
            </h5>
            <date-range-picker
              v-model="rangeactivity"
              :wrap="true"
              opens="left"
              class="inline-picker"
              @update="fetchActivity()"
            />
          </div>
          <app-timeline>
            <app-timeline-item
              v-for="activity in activities"
              :key="activity.id+activity.name"
              :variant="timelineVariant[activity.type].variant"
              :icon="timelineVariant[activity.type].icon"
            >
              <div v-if="activity.type === 'task'">
                <div class="d-flex justify-content-between align-items-center">
                  <router-link :to="{ path: '/tasks/all', query: { q: activity.name } }">
                    <h6 class="text-primary">
                      {{ activity.name }}
                    </h6>
                  </router-link>
                  <b-badge
                    class="mb-50"
                    :variant="resolveStatusVariant(activity.status, activity.type).badge"
                  >
                    {{ resolveStatusVariant(activity.status, activity.type).label }}
                  </b-badge>
                </div>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <router-link
                    v-if="type === 'customer'"
                    :to="{ name: 'project-detail', params: {id: activity.project.id.toString()} }"
                  >
                    <p
                      class="mb-0 text-primary"
                    >
                      {{ `${activity.project.name}` }}
                      <b-badge
                        v-if="activity.project.tgl_reminder"
                        variant="light-primary"
                      >
                        {{ formatShortDate(activity.project.tgl_reminder) }}
                      </b-badge>
                    </p>
                  </router-link>
                  <small
                    v-b-popover.hover.top="formatShortDate(activity.date)"
                    class="timeline-item-time text-nowrap text-muted"
                    v-text="timesAgo(activity.date)"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <b-media
                    v-if="type === 'customer'"
                    class="mt-1 mb-50"
                  >
                    <template #aside>
                      <b-avatar
                        size="36"
                        :text="avatarText(activity.userCreate.name)"
                        variant="light-primary"
                        :src="activity.userCreate.photo && activity.userCreate.photo !== 'default.png' ?
                          `${$restHTTP}/assets/img/profile-picture/${activity.userCreate.id}/${activity.userCreate.photo}` :
                          `${$restHTTP}/assets/img/profile-picture/default.png`
                        "
                      />
                    </template>
                    <router-link :to="{ name: 'user-detail', params: {id: activity.userCreate.id} }">
                      <h6 class="text-primary m-0">
                        {{ activity.userCreate.name }}
                      </h6>
                    </router-link>
                    <small
                      v-if="activity.userCreate.role"
                      class="d-block text-muted"
                    >
                      {{ activity.userCreate.role.name }}
                    </small>
                  </b-media>
                  <div v-else>
                    <router-link
                      :to="{ name: 'project-detail', params: {id: activity.project.id.toString()} }"
                    >
                      <h6
                        class="m-0 mt-50 mb-25 text-primary"
                      >
                        {{ `${activity.project.name}` }}
                      </h6>
                    </router-link>
                    <b-badge
                      v-if="activity.project.tgl_reminder"
                      variant="light-primary"
                    >
                      {{ formatShortDate(activity.project.tgl_reminder) }}
                    </b-badge>
                  </div>
                  <div
                    v-if="activity.userCreate.id === $store.getters.getCurrentUser.user.id"
                    class="ml-auto d-flex flex-wrap justify-content-end align-items-center"
                  >
                    <b-button
                      v-if="activity.status.id === 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="outline-primary"
                      class="d-flex align-items-center mt-50 py-75"
                      @click="doneTask(activity)"
                    >
                      <feather-icon
                        icon="CheckSquareIcon"
                        size="18"
                        class="mr-50"
                      />
                      Selesaikan
                    </b-button>
                    <b-dropdown
                      v-if="activity.info.tags.filter(el => el.id === 6 || el.id === 7).length > 0"
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                      class="mt-25 ml-50"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <div
                        v-for="(tag, index) in activity.info.tags"
                        :key="index"
                      >
                        <b-dropdown-item
                          v-if="type === 'customer' && tag.id === 6 && ($root.role === 2 || customer.isAssigned)"
                          :to="{ name: 'addquote', params: { selectedCustomer: customer, selectedProject: activity.project, resetFormState: true } }"
                        >
                          <span class="text-primary">Buat Quote</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-else-if="type === 'customer' && tag.id === 7 && ($root.role === 2 || customer.isAssigned)"
                          :to="{ name: 'salesorder-add', params: { selectedCustomer: customer } }"
                        >
                          <span class="text-info">Buat Sales Order</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <div v-if="activity.type === 'quote'">
                <div class="d-flex justify-content-between align-items-center">
                  <router-link :to="{ name: 'quotation-detail', params: { id: activity.id.toString(), version: activity.info.last_version.toString() } }">
                    <h6 class="text-primary">
                      {{ activity.name }}
                    </h6>
                  </router-link>
                  <b-badge
                    class="mb-50"
                    :variant="resolveStatusVariant(activity.status, activity.type).badge"
                  >
                    {{ resolveStatusVariant(activity.status, activity.type).label }}
                  </b-badge>
                </div>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <router-link
                    v-if="type === 'customer'"
                    :to="{ name: 'project-detail', params: {id: activity.project.id.toString()} }"
                  >
                    <p
                      class="mb-0 text-primary"
                    >
                      {{ `${activity.project.name}` }}
                      <b-badge
                        v-if="activity.project.tgl_reminder"
                        variant="light-primary"
                      >
                        {{ formatShortDate(activity.project.tgl_reminder) }}
                      </b-badge>
                    </p>
                  </router-link>
                  <small
                    v-b-popover.hover.top="formatShortDate(activity.date)"
                    class="timeline-item-time text-nowrap text-muted"
                    v-text="timesAgo(activity.date)"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <b-media
                    v-if="type === 'customer'"
                    class="mt-1 mb-50"
                  >
                    <template #aside>
                      <b-avatar
                        size="36"
                        :text="avatarText(activity.userCreate.name)"
                        variant="light-primary"
                        :src="activity.userCreate.photo && activity.userCreate.photo !== 'default.png' ?
                          `${$restHTTP}/assets/img/profile-picture/${activity.userCreate.id}/${activity.userCreate.photo}` :
                          `${$restHTTP}/assets/img/profile-picture/default.png`
                        "
                      />
                    </template>
                    <router-link :to="{ name: 'user-detail', params: {id: activity.userCreate.id} }">
                      <h6 class="text-primary m-0">
                        {{ activity.userCreate.name }}
                      </h6>
                    </router-link>
                    <small
                      v-if="activity.userCreate.role"
                      class="d-block text-muted"
                    >
                      {{ activity.userCreate.role.name }}
                    </small>
                  </b-media>
                  <div v-else>
                    <router-link
                      :to="{ name: 'project-detail', params: {id: activity.project.id.toString()} }"
                    >
                      <h6
                        class="m-0 mt-50 mb-25 text-primary"
                      >
                        {{ `${activity.project.name}` }}
                      </h6>
                    </router-link>
                    <b-badge
                      v-if="activity.project.tgl_reminder"
                      variant="light-primary"
                    >
                      {{ formatShortDate(activity.project.tgl_reminder) }}
                    </b-badge>
                  </div>
                  <div class="ml-auto d-flex flex-wrap justify-content-end align-items-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="outline-danger mt-50"
                      @click="$refs.pdf.show(`${$restHTTP}/rest/forward/${activity.id}`, activity.name)"
                    >
                      <b-img
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="16"
                        class="mr-25"
                      />
                      Preview
                    </b-button>
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                      class="mt-25 ml-50"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        :to="{ name: 'quotation-detail', params: {id: activity.id.toString(), version: activity.info.last_version.toString()} }"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          class="mr-50 mb-25 text-info"
                        />
                        <span class="text-info">
                          Lihat Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-divider v-if="activity.status.id !== 6 && resolveUserPermission(activity.userCreate.id)" />
                      <b-dropdown-item
                        v-if="activity.status.id === 2 && $root.role === 2 && activity.userCreate.id !== $store.getters.getCurrentUser.user.id"
                        @click="acceptRejectQuote(0, activity.id)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-danger"
                        />
                        <span class="text-danger">
                          Reject Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="activity.status.id === 3 && resolveUserPermission(activity.userCreate.id)"
                        @click="rejectByCustomer(activity.id)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-danger"
                        />
                        <span class="text-danger">
                          Reject Quote by Customer
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="activity.status.id >= 1 && activity.status.id <= 5 && activity.status.id !== 3 && resolveUserPermission(activity.userCreate.id)"
                        @click="closeQuote(activity.id)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-warning"
                        />
                        <span class="text-warning">
                          Close Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="activity.status.id === 7 && resolveUserPermission(activity.userCreate.id)"
                        @click="cancelQuote(activity.id)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-warning"
                        />
                        <span class="text-warning">
                          Cancel Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="activity.status.id === 3 && resolveUserPermission(activity.userCreate.id)"
                        @click="forwardQuote(activity.id, activity.version)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-info"
                        />
                        <span class="text-info">
                          Forward Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="activity.status.id === 5 && resolveUserPermission(activity.userCreate.id)"
                        @click="reviseQuote(activity.id, activity.version)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-info"
                        />
                        <span class="text-info">
                          Revise Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="(activity.status.id === 1 || activity.status.id === 4) && resolveUserPermission(activity.userCreate.id)"
                        @click="editQuote(activity.id, activity.version)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-info"
                        />
                        <span class="text-info">
                          Edit Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="(activity.status.id === 1 || activity.status.id === 4) && resolveUserPermission(activity.userCreate.id)"
                        @click="submitQuote(activity.id)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-primary"
                        />
                        <span class="text-primary">
                          Submit Quote
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="activity.status.id === 2 && $root.role === 2 && activity.userCreate.id !== $store.getters.getCurrentUser.user.id"
                        @click="acceptRejectQuote(1, activity.id)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50 mb-25 text-primary"
                        />
                        <span class="text-primary">
                          Approve Quote
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </app-timeline-item>
          </app-timeline>
          <div class="d-flex justify-content-center mt-1">
            <b-button
              v-if="!(activities.length < limit)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="loadMore()"
            >
              Lihat Lebih Banyak
            </b-button>
          </div>
        </div>
        <div
          v-else
        >
          <h5>
            Aktivitas
          </h5>
          <h6 class="text-muted mt-1">
            Belum ada aktivitas.
          </h6>
        </div>
      </div>
    </transition>

    <b-modal
      id="recurring-modal"
      ref="recurringModal"
      hide-header
      hide-footer
      hide-header-close
      centered
    >
      <div class="d-block text-center mt-1">
        <h3>Buat task {{ selectedRecurring.description }} lagi?</h3>
        <h6 class="mt-50">
          Ulangi task {{ selectedRecurring.description }} lagi untuk tanggal {{ formatDate(nextRecurring) }} ?
          <b-form-datepicker
            v-model="nextRecurring"
            button-only
            right
            locale="id-ID"
            aria-controls="example-input"
            button-variant="outline-primary"
            class="custom-date-picker"
            :min="new Date()"
          >
            <template #button-content>
              <span class="text-primary cursor-pointer mr-50">Ubah tanggal <feather-icon
                size="14"
                icon="CalendarIcon"
              /></span>
            </template>
          </b-form-datepicker>
        </h6>
      </div>
      <div class="d-flex justify-content-center mt-2 mb-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="newRecurring(selectedRecurring)"
        >
          Buat Task
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-danger"
          @click="$bvModal.hide('recurring-modal')"
        >
          Tidak Perlu
        </b-button>
      </div>
    </b-modal>

    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import {
  BMedia, BAvatar, BButton, BImg, BBadge, BDropdown, BDropdownItem, BSpinner, BDropdownDivider, VBPopover,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PdfPreview from '@/components/misc/PdfPreview.vue'

export default {
  components: {
    BMedia,
    BAvatar,
    BButton,
    BImg,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BDropdownDivider,
    AppTimeline,
    AppTimelineItem,
    DateRangePicker,
    PdfPreview,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
      loading: true,
      rangeactivity: {},
      limit: 10,
      activities: [],
      timelineVariant: {
        task: {
          variant: 'warning',
          icon: 'CheckSquareIcon',
        },
        quote: {
          variant: 'info',
          icon: 'FileTextIcon',
        },
        salesorder: {
          variant: 'primary',
          icon: 'AwardIcon',
        },
      },

      // task related data
      nextRecurring: new Date(),
      selectedRecurring: {},
    }
  },
  created() {
    this.fetchActivity()
  },
  methods: {
    async fetchActivity() {
      this.loading = true

      await this.$store.dispatch('getDailyReport', {
        customerid: this.type === 'customer' ? this.id : null,
        userid: this.type === 'user' ? this.id : null,
        pagination: `{
          limit: ${this.limit}
          offset: 0
        }`,
        filter: this.rangeactivity.startDate ? `{
          date: {
            date_min: "${new Date(this.rangeactivity.startDate).toISOString().slice(0, 10)}",
            date_max: "${new Date(this.rangeactivity.endDate).toISOString().slice(0, 10)}",
          }
        }` : null,
      }).then(result => {
        this.loading = false
        if (result.length > 0) {
          this.activities = result.map(el => ({
            ...el,
            info: JSON.parse(el.info),
          }))
        }
      }).catch(() => {
        this.loading = false
      })
    },
    async loadMore() {
      this.limit += 10
      await this.fetchActivity()
    },
    formatDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date)
      return moment(parsedDate).locale('id').format('dddd, DD MMMM YYYY • H:mm')
    },
    timesAgo(time) {
      return moment(time).locale('id').fromNow()
    },
    formatShortDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date)
      return moment(parsedDate).locale('id').format('D MMMM YYYY')
    },
    resolveStatusVariant(status, type) {
      const quoteStatusVariant = this.$store.getters.getStatusOptions
      const taskStatusVariant = {
        0: {
          badge: 'light-warning',
          label: 'Belum Selesai',
        },
        1: {
          badge: 'light-primary',
          label: 'Sudah Selesai',
        },
      }

      if (type === 'task') {
        return taskStatusVariant[status.id]
      }

      if (type === 'quote') {
        return quoteStatusVariant[status.id]
      }

      return { badge: 'secondary', label: status.name }
    },
    doneTask(task) {
      this.$swal({
        title: 'Selesaikan Task?',
        text: 'Konfirmasi jika anda ingin menyelesaikan task',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('doneTask', task.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Task berhasil diselesaikan!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })

            this.fetchActivity()

            // if (task.recurring > 0) {
            //   this.selectedRecurring = task
            //   this.nextRecurring = task.due_date ? new Date(Date.parse(task.due_date) + (task.recurring * 8.64e+7)) : new Date(Date.now() + (task.recurring * 8.64e+7))
            //   this.$refs.recurringModal.show()
            // }
          })
        }
      })
    },
    newRecurring(task) {
      this.$store.dispatch('addTask', {
        description: task.description,
        project_id: task.project.id,
        recurring: task.recurring,
        due_date: new Date(Date.parse(this.nextRecurring)).toISOString().slice(0, 10),
        tags: task.tags.length > 0 ? task.tags.map(el => el.id) : null,
      }).then(() => {
        this.$refs.recurringModal.hide()
        this.selectedRecurring = {}
        this.nextRecurring = new Date()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil menambahkan recurring task baru!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Terjadi kesalahan!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      })
    },
    submitQuote(id) {
      this.$swal({
        title: 'Submit Quote?',
        text: 'Konfirmasi jika anda ingin submit quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.commit('setLoadingQuotes', true)
          this.$store.dispatch('submitQuote', { id: Number(id) }).then(async response => {
            if (response.data.submitQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil disubmit',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchActivity()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    closeQuote(id) {
      this.$swal({
        title: 'Close Quote?',
        text: 'Konfirmasi jika anda ingin menclose quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'text-warning btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.commit('setLoadingQuotes', true)
          await this.$store.dispatch('closeQuote', { id: Number(id) }).then(async response => {
            if (response.data.closeQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil diclose!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchActivity()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    cancelQuote(id) {
      this.$swal({
        title: 'Cancel Quote?',
        text: 'Konfirmasi jika anda ingin mencancel quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'text-warning btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.commit('setLoadingQuotes', true)
          await this.$store.dispatch('closeQuote', { id: Number(id) }).then(async response => {
            if (response.data.closeQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil dicancel!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchActivity()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    acceptRejectQuote(status, id) {
      this.$swal({
        title: status === 0 ? 'Reject Quote?' : 'Approve Quote?',
        text: status === 0 ? 'Konfirmasi jika anda ingin mereject quote' : 'Konfirmasi jika anda ingin mengapprove quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: status === 0 ? 'btn btn-danger' : 'btn btn-primary',
          cancelButton: status === 0 ? 'text-danger btn btn-outline-danger ml-1' : 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value && status === 0) {
          this.$swal({
            title: 'Alasan Reject',
            input: 'text',
            html: '<p>Beri alasan reject di bawah ini.</p',
            showCancelButton: true,
            confirmButtonText: 'Lanjutkan',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1',
            },
            buttonsStyling: false,
          }).then(async notes => {
            if (notes.isConfirmed) {
              this.$store.commit('setLoadingQuotes', true)
              await this.$store.dispatch('acceptRejectQuote', {
                id: Number(id),
                status,
                notes: notes.value,
              }).then(async response => {
                if (response.data.acceptRejectQuote.status.toLowerCase() === 'success') {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: status === 0 ? 'Berhasil direject!' : 'Berhasil diapprove!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.fetchActivity()
                } else {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.submitQuote.message,
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  })
                }
              }).catch(() => {
                this.$store.commit('setLoadingQuotes', false)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Terjadi kesalahan',
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                })
              })
            }
          })
        } else if (result.value && status === 1) {
          this.$store.commit('setLoadingQuotes', true)
          await this.$store.dispatch('acceptRejectQuote', {
            id: Number(id),
            status,
            notes: '',
          }).then(async response => {
            if (response.data.acceptRejectQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: status === 0 ? 'Berhasil direject!' : 'Berhasil diapprove!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchActivity()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    rejectByCustomer(id) {
      this.$swal({
        title: 'Reject Quote?',
        text: 'Konfirmasi jika anda ingin mereject quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$swal({
            title: 'Alasan Reject',
            input: 'text',
            html: '<p>Beri alasan reject di bawah ini.</p',
            showCancelButton: true,
            confirmButtonText: 'Lanjutkan',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1',
            },
            buttonsStyling: false,
          }).then(async notes => {
            if (notes.isConfirmed) {
              this.$store.dispatch('rejectQuoteByCustomer', { id: Number(id), notes: notes.value }).then(response => {
                if (response.data.rejectQuoteByCustomer.status.toLowerCase() === 'success') {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil direject!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.fetchActivity()
                } else {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.rejectQuoteByCustomer.message,
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  })
                }
              }).catch(() => {
                this.$store.commit('setLoadingQuotes', false)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Terjadi kesalahan',
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                })
              })
            }
          })
        }
      })
    },
    reviseQuote(id, version) {
      this.$router.push({
        name: 'revisequote',
        params: {
          id: id.toString(),
          version: version.toString(),
        },
      })
    },
    forwardQuote(id, version) {
      this.$router.push({
        name: 'quotation-detail',
        params: {
          id: id.toString(),
          version: version.toString(),
          openForwardQuote: true,
        },
      })
    },
    resolveUserPermission(idUser) {
      return (this.$root.role === 2 || idUser === this.$store.getters.getCurrentUser.user.id)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';

.inline-picker {
  .reportrange-text[data-v-4391f606]{
    border: 0;
    text-align: right !important;
    padding: 0;
    padding-top: 2px;
    cursor: default;
  }

  span[data-v-4391f606] {
    color: $primary;
    font-weight: 500;
    cursor: pointer;
  }

  @media(min-width: 400px) {
    span[data-v-4391f606]:before {
      content: 'Tanggal : ';
      color: $secondary;
      font-weight: 400;
    }
  }

  .daterangepicker.opensleft[data-v-4391f606] {
    right: 0;
  }
}
</style>
